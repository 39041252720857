<template>
  <div v-if="canAccessPage">
    <alert ref="Alert"></alert>
    <user-request-modal ref="UserRequestModal"></user-request-modal>

    <div style="flex-grow:1;">
      <h1>{{ $t('menu.leads-dashboard') }}</h1>
    </div>

    <div class="dashboard-tiles mb-4">
      <icon-card
        :title="$t('dashboards.totalLeads')"
        :value="this.leadsDashboard.totalLeads"
        @cardClicked="totalLeadsClicked"
      />
      <icon-card
        :title="$t('dashboards.totalQuoteAmount')"
        :value="this.leadsDashboard.totalQuoteAmount"
        @cardClicked="totalAmountClicked"
      />
      <icon-card
        :title="$t('dashboards.myLeads')"
        :value="this.leadsDashboard.myLeads"
        @cardClicked="myLeadsClicked"
      />
      <icon-card
        :title="$t('dashboards.past-due-follow-ups')"
        :value="this.leadsDashboard.pastDueFollowUps"
        @cardClicked="pastDueFollowUps"
      />
    </div>

    <datatable-heading
      ref="DatatableHeading"
      :hasTitleSection="false"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange"
    >
      <template v-slot:button>
        <download-excel :data="json_data" type="csv">
          <b-button v-b-modal.modalright variant="primary" size="lg">
            Export
          </b-button>
        </download-excel>
        <b-button
          class="ml-2"
          v-b-modal.modalright
          variant="primary"
          size="lg"
          :to="{ name: 'quick-add' }"
        >
          Quick Add
        </b-button>
        <b-button
          class="ml-2"
          v-b-modal.modalright
          variant="primary"
          size="lg"
          :to="{ name: 'AddCompany' }"
        >
          Add New
        </b-button>
      </template>
    </datatable-heading>

    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="companyID"
              :api-url="apiBase"
              :http-fetch="tableFetch"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              :sort-order="sortOrder"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
              <template slot="isTime" slot-scope="props">
                <div>
                  <i
                    v-show="props.rowData.isTime"
                    class="iconsminds-clock"
                    style="font-weight: bold; font-size: 18px; color: red;"
                    v-b-tooltip.hover.left
                    title="Time is money earned!"
                  />
                </div>
              </template>
              <template slot="description" slot-scope="props">
                <center><div :class="closeRatioTagClass(props.rowData.description)" v-if="(props.rowData.description && props.rowData.description != 'Closed NA')">
                  {{ (props.rowData.description == 'Closed NA') ? 'N/A' : `${props.rowData.closeRatio * 100}%` }}
                </div></center>
                <small :class="{ 'text-bold' : props.rowData.isTime }">{{props.rowData.description || 'N/A'}}</small>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>

    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('edit')">
        <i class="simple-icon-pencil" />
        <span>Edit/View</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('userRequest')">
        <i class="simple-icon-user-follow" />
        <span>Add Login</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('assign_login')" v-if="showAssignLogin">
        <i class="simple-icon-arrow-right-circle" />
        <span>Assign Login</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
  <div v-else>
    <p>You do not have the required permissions to access the Leads Dashboard page.</p>
  </div>
</template>

<script>
import alert from '../../../components/Common/Alert.vue'
import Vuetable from 'vuetable-2'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl, CompanyContext } from '../../../constants/config'

import DatatableHeading from '../../../containers/datatable/DatatableHeading.vue'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import CompanyMixin from '../../../mixins/CompanyMixin'

// so we can set our messages in the layout if needed
import { mapMutations } from 'vuex'

import IconCard from '../../../components/Cards/IconCard'
import JsonExcel from 'vue-json-excel'

import { mutations } from '../../../store/FilterMenu'

import userRequestModal from '../../../components/Modals/UserRequestModal.vue'
import bus from '../../../main'

export default {
  props: ['title'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Alert: alert,
    'icon-card': IconCard,
    downloadExcel: JsonExcel,
    'user-request-modal': userRequestModal
  },
  async created () {
    await this.getLeadsDashboard(localStorage.getItem('employeeID'))
  },
  mounted () {
    const urlParams = new URL(location.href).searchParams
    this.loginId = urlParams.get('loginid')
    this.userName = urlParams.get('userName')
    if (this.loginId) {
      this.setInfoMessage(`Right click the company you want to assign ${this.userName} to and choose "Assign Login"`)
      this.showAssignLogin = true
    }
    if (urlParams.toString().length === 0) {
      mutations.clearFilters()
      mutations.setFilterByColumn('active', 1)
      this.$refs.DatatableHeading.fireFilterEvent()
    }
  },
  mixins: [CompanyMixin],
  data () {
    var _idfield = 'companyID'
    return {
      json_data: [],
      sortOrder: [],
      apiBase: BeaconapiUrl + '/api/lead/table',
      sort: '',
      page: 1,
      perPage: 25,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: 'inquiryReceived',
          sortField: 'inquiryReceived',
          title: 'Inquiry Received',
          titleClass: '',
          dataClass: 'text-muted',
          width: '5%',
          filterType: 'date'
        },
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Company ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '5%',
          filterType: 'number'
        },
        {
          name: 'companyLegalName',
          sortField: 'companyLegalName',
          title: 'Company Legal Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '5%',
          filterType: 'text'
        },
        {
          name: 'programs',
          sortField: 'programs',
          title: 'Programs',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right text-muted',
          width: '5%',
          filterType: 'text'
        },
        // state
        {
          name: 'leadDoingBusinessInState',
          sortField: 'leadDoingBusinessInState',
          title: 'State',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right text-muted',
          width: '5%',
          filterType: 'text'
        },
        {
          name: 'processStep',
          sortField: 'processStep',
          title: 'Process Step',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right text-muted',
          width: '5%',
          filterType: 'text'
        },
        {
          name: '__slot:description',
          sortField: 'description',
          title: 'Lead Description',
          titleClass: '',
          dataClass: 'center aligned text-center text-muted',
          width: '5%',
          filterType: 'text'
        },
        {
          name: 'quote',
          sortField: 'quote',
          title: 'Quote',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right text-muted',
          width: '5%',
          filterType: 'number'
        },
        {
          name: 'salesRep',
          sortField: 'salesRep',
          title: 'Sales Rep',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right text-muted',
          width: '5%',
          filterType: 'text'
        },
        {
          name: '__slot:isTime',
          sortField: 'isTime',
          title: 'TIME',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right text-muted',
          width: '5%',
          filterType: 'bool'
        },
        {
          name: 'hasQuote',
          sortField: 'hasQuote',
          title: 'Has Quote',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right text-muted',
          width: '5%',
          filterType: 'bool',
          visible: false
        },
        {
          name: 'active',
          sortField: 'active',
          title: 'Active',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right text-muted',
          width: '5%',
          filterType: 'active',
          visible: false
        }
        // {
        //   name: 'horizonStatus',
        //   sortField: 'horizonStatus',
        //   title: 'Status',
        //   titleClass: '',
        //   dataClass: 'text-muted',
        //   width: '5%',
        //   filterType: 'text'
        // },
      ],
      showAssignLogin: false,
      loginId: null,
      userName: null
    }
  },

  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),

    // Vue Table plumbing methods (standardized)
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected-row'
      } else if (dataItem.isTime) {
        outputclass = 'time-row'
      }
      return outputclass
    },
    cellclicked (dataitem, field, event) {},
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    rowClicked (dataItem, event) {
      const itemId = dataItem[this.$data.idfield]

      if (this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(
          x => x !== itemId
        )
        localStorage.setItem(CompanyContext, null)
      } else {
        this.$refs.vuetable.selectedTo.pop()
        this.$refs.vuetable.selectedTo.push(itemId)
        localStorage.setItem(CompanyContext, itemId)
        bus.$emit('buildcompanycontext')
        bus.$emit('opencompanysubmenu')
      }

      this.selectedItems = this.$refs.vuetable.selectedTo
    },

    // End of Standard Vue Table methods

    // Component-specific Vue Table methods

    onContextMenuAction (action) {
      switch (action) {
        case 'edit': {
          this.$router.push({
            name: 'EditCompany',
            params: { companyID: this.selectedItems[0] }
          })
          break
        }
        case 'userRequest': {
          this.$refs.UserRequestModal.show({
            companyID: this.selectedItems[0],
            exitFunction: () => {
              this.refreshVueTable()
            }
          })

          break
        }
        case 'assign_login': {
          this.assignLogin({ companyid: this.selectedItems[0], userID: this.loginId }, this.userName)
          break
        }
        default:
          break
      }
    },

    async assignLogin (payload, userName) {
      await this.addUserToCompany(payload, userName).then(response => {
        this.refreshVueTable()
      })
    },

    // End Component-specific Vue Table methods

    totalLeadsClicked () {
      mutations.clearFilters()
      mutations.setFilterByColumn('active', 1)
      this.$refs.DatatableHeading.fireFilterEvent()
    },
    totalAmountClicked () {
      // Filter the view to HasQuote = True when the TotalAmount card is clicked (PBI 324)
      mutations.clearFilters()
      mutations.setFilterByColumn('hasQuote', 1)
      mutations.setFilterByColumn('active', 1)
      this.$refs.DatatableHeading.fireFilterEvent()
    },
    myLeadsClicked () {
      var employeeName = localStorage.getItem('employeeName')
      if (employeeName !== 'null' && employeeName !== null) {
        mutations.clearFilters()
        mutations.setFilterByColumn('active', 1)
        mutations.setFilterByColumn('salesRep', employeeName)
        this.$refs.DatatableHeading.fireFilterEvent()
      } else {
        this.$refs.Alert.show({
          title: 'Account Error',
          message:
            'There was an error getting your employee information. Please log out and log back in. Contact support if this error persists.',
          okButton: 'Okay'
        })
      }
    },
    pastDueFollowUps () {
      var employeeName = localStorage.getItem('employeeName')
      if (employeeName !== 'null' && employeeName !== null) {
        this.$router.push({
          name: 'followUps',
          params: { employeeName: employeeName, isOverdue: true }
        })
      } else {
        this.$refs.Alert.show({
          title: 'Account Error',
          message:
            'There was an error getting your employee information. Please log out and log back in. Contact support if this error persists.',
          okButton: 'Okay'
        })
      }
    },
    closeRatioTagClass (desc) {
      return desc ? 'ratioTag ' + desc.replace(/\s/g, '') : 'ratioTag ClosedLost'
    }
  },
  computed: {
    // Added this because navigating 'home' currently takesall users you to Leads Dashboard, but we will have other dashboards in the future.
    // Commented out code in menu.js and router.js that would navigate user to unauthorized page if unauthorized to access Leads Dashboard. Can implement when other dashboards available.
    canAccessPage () {
      var permissions = JSON.parse(localStorage.getItem('userPermissions'))
      return permissions.some(p => p.permissionName === 'Leads Dashboard')
    }
  }
}
</script>
<style scoped>
.time-text {
  color: red;
  font-size: 24px;
}
.ratioTag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
}
.ClosedLost {
  background-color: #FFFFFF;
  border: 2px solid #8f8f8f;
  color: #383838;
  width: 40px;
  height: 40px;
}
.ColdLead {
  background-color: #5DADE2;
  color: #FFFFFF;
}
.Lead {
  background-color: #52BE80;
  color: #FFFFFF;
}
.WarmLead {
  background-color: #EB984E;
  color: #FFFFFF;
}
.HotLead {
  background-color: #EC7063;
  color: #FFFFFF;
}
.ClosedWon {
  background-color: #FFFFFF;
  border: 2px solid #8f8f8f;
  color: #383838;
  width: 40px;
  height: 40px;
}
</style>
