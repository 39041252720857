<template>
  <b-card class="text-center card" @click="cardClicked">
    <i :class="icon"/>
    <p class="card-text font-weight-semibold mb-0">{{ title }}</p>
    <p class="card-value text-center">{{ value }}</p>
  </b-card>
</template>
<script>
export default {
  props: {
    title: { type: String, default: 'icon-card-title' },
    icon: { type: String },
    value: { default: 0 }
  },
  methods: {
    cardClicked () {
      this.$emit('cardClicked')
    }
  }
}
</script>
